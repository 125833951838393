//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToUserURL, ConvertToProductURL, ConvertToPolicyURL, ConvertToBroadcastURL } from "./hyperlink.pipe";
import { ConvertByteForPreview, ConvertDashToGuest, ConvertDashToGuestInitial, ConvertEmptyToDash, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace as ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, InitiateWithoutUTC, CurrencyValue, SafePipe, ConvertStringToLowerCase, ConvertStringToUpperCase} from "./converter.pipe";
import { TrimMedium, TrimShort, TrimLong, TrimLarge, TrimContent } from "./trim.pipe";
import { CustomCurrency } from "./customcurrency.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToPolicyURL,
			ConvertToBroadcastURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			TrimShort,
			TrimMedium,
			TrimLarge,
			TrimLong,
			TrimContent,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			CurrencyValue,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			CustomCurrency,
			ConvertDashToGuest,
			ConvertDashToGuestInitial,
			ConvertStringToLowerCase,
			ConvertStringToUpperCase,
			SafePipe
		],
		exports:
		[
			ConvertToProductURL,
			ConvertToPolicyURL,
			ConvertToBroadcastURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			TrimShort,
			TrimMedium,
			TrimLarge,
			TrimLong,
			TrimContent,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			CustomCurrency,
			CurrencyValue,
			ConvertDashToGuest,
			ConvertDashToGuestInitial,
			ConvertStringToLowerCase,
			ConvertStringToUpperCase,
			SafePipe
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion