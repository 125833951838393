<div class="DivPageContainer" id="divMainLandingContainer">
	<section class="SectionMain" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormHorizontalContainer" id="divMainLanding">
			<div class="DivForm2Column DivHeaderCenterContainer">
				<div class="DivHeaderContentContainer">
					<h1 class="FontSoftColor">Bisnis Ritel</h1>
					<p class="FontSoftColor">Pilih produk asuransi Ritel terbaik dan terpercaya dari BRINS sesuai dengan kebutuhan Anda.</p>
					<!-- <button class="ButtonSubmit" (click)="goToSignIn();"><label class="FontSoftColor">Login</label></button> -->
				</div>
			</div>

			<div class="DivForm2Column DivSocialMediaCenterContainer">
				<div class="DivSocialMedia">
					<img [src]="socialMedia.Path" *ngFor="let socialMedia of _arraySocialMedia" (click)="goToSNS(socialMedia.Link!)">
				</div>
			</div>
		</div>
	</section>

	<section class="SectionSecondary">
		<div class="DivFormVerticalContainer DivSiteContainer" id="divSiteLandingContainer">
			<h1 class="Bold">Produk Unggulan</h1>

			<div class="DivHeaderProductLanding">
				<div class="DivHeaderProductDescription">
					<h2>Kontra Bank Garansi</h2>

					<div class="DivProductContent">
						<p>Produk yang memberikan jaminan atas kerugian yang timbul dari klaim gugatan hukum yang dilayangkan terhadap Direksi dan Pejabat perusahaan yang telah atau dianggap telah melakukan Wrongful Act dalam kapasitas mereka dalam hal manajerial atau operasional.</p>
						<button class="ButtonSubmit" for="labelSelengkapnya" (click)="goToProductKontraBankGaransi()"><label class="FontSoftColor" id="labelSelengkapnya">Selengkapnya &#x2192;</label></button>
					</div>
				</div>

				<div class="DivHeaderProductImageDescription" [ngStyle]="getImageKebakaran()">
					<img src="../../../assets/gifs/transparent.gif">
				</div>
			</div>

			<div class="DivFormHorizontalContainer AlignStretch">
				<div class="DivProductLanding">
					<div class="DivProductImageDescription" [ngStyle]="getImageKebongkaran()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi Kerugian<br>Untuk Agunan</h2>

						<div class="DivProductContent">
							<div class="DivSubProductContent">
								<p>Penutupan Asuransi Agunan Kredit melalui aplikasi yang meliputi
								</p>
								<ul>
									<li>Asuransi Harta Benda (Property)</li>
									<li>Asuransi Kendaraan Bermotor (Motor Vehicle)
									</li>
									<li>Asuransi Alat-alat Berat (Heavy Equipment)</li>
									<li>Asuransi Mesin (Machinery Breakdown
									</li>
								</ul>
							</div>

							<button class="ButtonSubmit" for="labelSelengkapnya" (click)="goToProductAgunan()"><label class="FontSoftColor" id="labelSelengkapnya">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>

				<div class="DivProductLanding">
					<div class="DivProductImageDescription" [ngStyle]="getImageTerorisme()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi Uang</h2>

						<div class="DivProductContent">
							<p>Asuransi yang bertujuan untuk membebaskan BANK dari kerugian-kerugian keuangan yang meliputi CIT, CIS, dan CICB.
							</p>
							<button class="ButtonSubmit" for="labelSelengkapnya" (click)="goToProductUang()"><label class="FontSoftColor" id="labelSelengkapnya">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>
			</div>

			<div class="DivFormHorizontalContainer AlignStretch">
				<div class="DivFooterProductLanding BorderBottomLeft35">
					<div class="DivProductImageDescription BorderBottomLeft35" [ngStyle]="getImageGempaBumi()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Kredit Kepemilikan<br>Rumah (KPR)</h2>

						<div class="DivProductContent">
							<p>Asuransi KPR (Kredit Pemilikan Rumah) merupakan Asuransi yang mengcover Konsumen BRI yang mengajukan  KPR melalui Bank BRI.
							</p>
							<button class="ButtonSubmit" for="labelSelengkapnya" (click)="goToProductKpr()"><label class="FontSoftColor" id="labelSelengkapnya">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>

				<div class="DivFooterProductLanding BorderBottomRight35">
					<div class="DivProductImageDescription" [ngStyle]="getImageRekayasa()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi<br>Kecelakaan Diri</h2>

						<div class="DivProductContent">
							<p>Asuransi yang memberikan jaminan atas risiko yang timbul akibat kecelakaan seperti kematian, cacat tetap seluruh/sebagian tubuh dan biaya perawatan atau pengobatan yang diperlukan sesuai ketentuan jaminan sebagai akibat dari kecelakaan tersebut.</p>
							<button class="ButtonSubmit" for="labelSelengkapnya" (click)="goToProductKecelakaanDiri()"><label class="FontSoftColor" id="labelSelengkapnya">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>