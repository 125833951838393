//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkStrategy } from "ngx-quicklink";
import { HomeComponent } from "./components/home/home.component";
import { LandingComponent } from "./components/landing/landing.component";
import { PartnerComponent } from "./components/partner/partner.component";
import { PublicGuardService } from "./services/guards/public.guard.service";


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full"
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [PublicGuardService],
		children:
		[
			{
				path: "",
				component: LandingComponent,
			},
			{
				path: "product",
				loadChildren:() => import("./components/product/product.module").then(pageModule => pageModule.ProductModule)
			},
			{
				path: "partner",
				component: PartnerComponent,
			},
		]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy,
					scrollPositionRestoration: "top"
				}
			)
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
