//#region IMPORT

import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgSelectModule } from "@ng-select/ng-select";
import { QuicklinkModule } from "ngx-quicklink";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from "./app-routing.module";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { TableControlIncludeMiniComponent } from "./components/includes/tablecontrolmini.include/tablecontrolmini.include.component";
import { AppComponent } from "./components/root/app.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { HomeComponent } from "./components/home/home.component";
import { PwaService } from "./services/pwa.service";
import { TableControlIncludePageComponent } from "./components/includes/tablecontrolpage.include/tablecontrolpage.include.component";
import { LandingComponent } from "./components/landing/landing.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HorizontalstepperIncludeComponent } from "./components/includes/horizontalstepper.include/horizontalstepper.include.component";
import { AgreementComponent } from "./components/agreement/agreement.component";
import { ProductListComponent } from "./components/product/productlist/productlist.component";
import { PartnerComponent } from "./components/partner/partner.component";
import { ProductDetailComponent } from "./components/product/productdetail/productdetail.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			HomeComponent,
			TableControlIncludeComponent,
			TableControlIncludeMiniComponent,
			TableControlIncludePageComponent,
			LandingComponent,
			FooterComponent,
			HorizontalstepperIncludeComponent,
			AgreementComponent,
			ProductListComponent,
			ProductDetailComponent,
			PartnerComponent,
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			ReactiveFormsModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			MatCheckboxModule,
			ReactiveFormsModule,
			NgSelectModule,
			QuicklinkModule,
			NgxMaskModule.forRoot(),
			ServiceWorkerModule.register("ngsw-worker.js",
				{
					enabled: true,
					// Register the ServiceWorker as soon as the app is stable
					// or after 30 seconds (whichever comes first).
					registrationStrategy: "registerWhenStable:30000"
				}
			),
		],
		providers:
		[
			CurrencyPipe,
			DecimalPipe,
			QuicklinkModule,
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			}
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion