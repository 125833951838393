<div class="DivBodyContainer" (scroll)="scrolled($event)">
    <div id="divBodyHomeMenu">
		<nav id="navigationMainHome" [ngClass]="_booleanMenuMaximize ? 'NavHeaderMaximize' : 'NavHeaderMinimize' " (click)="!_booleanMenuMaximize && minimizeMenu(true)">
			<img id="imageLogoHeader" (click)="goToBRINSDIGITAL()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo BRI Insurance">
			<ng-container *ngIf="_booleanMenuMaximize">
				<ul *ngIf="_booleanMenuMaximize">
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="goToHome();">
						<a>Beranda</a>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Product) }" (click)="goToProductList();">
						<a>Produk</a>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Partner) }" (click)="goToPartner();">
						<a>Mitra</a>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Contact) }" (click)="goToContact();">
						<a>Kontak</a>
					</li>
				</ul>

				<div class="DivNavigationRight">
					<img id="imageLogoDigital" (click)="goToHome()" src="../../assets/logos/sakina/logo_ ritel.svg" alt="BRI Insurance - Logo Header Digital">
				</div>
			</ng-container>

			<ng-container *ngIf="!_booleanMenuMaximize">
				<div class="DivNavigationRight">
					<ul>
						<li>
							<img id="imageIconHamburger" (click)="minimizeMenu(true)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_hamburger_menu.svg">
						</li>
					</ul>
				</div>
			</ng-container>
		</nav>

		<nav id="navigationAside" class="NavMenuOverlay" [ngClass]="_booleanMenuBarMinimize ? 'NavMenuMinimizeOverlay' : 'NavMenuMinimizeNoOverlay'" >

			<div class="DivMenuHeader">
				<img id="imageLogoDigital" (click)="goToHome()" src="../../assets/logos/sakina/logo_ ritel.svg" alt="BRI Insurance - Logo Header Digital">
				<img (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_close.svg">
			</div>

			<ul *ngIf="!_booleanMenuMaximize" class="UlMenuMinimize ListNavigation">
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="goToHome()">
					<label>Beranda</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Product) }" (click)="goToProductList();">
					<label>Produk</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Partner) }" (click)="goToPartner();">
					<label>Mitra</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Contact) }" (click)="goToContact();">
					<label>Kontak</label>
				</li>
				<li>
					<img id="imageMainDomain" (click)="goToMainDomain()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo BRI Insurance">
				</li>
			</ul>
		</nav>
	</div>

    <div id="divMainDashboard">
		<router-outlet></router-outlet>
		<app-footer></app-footer>
	</div>
</div>