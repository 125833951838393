//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { LocationModel } from "./location.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductAsriModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product asri model, used for SPPA and policy.
		Author: Ibrahim Aziz.
		Created on : Monday, 19 October 2020.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:6.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	ReferenceNumber?: string;
	Policy?: string;

	Disclaimer?: boolean;
	SurveyAsriID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	/* POLICYHOLEDR - END */

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityName?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalName?: string;
	BuildingPostalCode?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	BuildingPrice?: number;
	InteriorPrice?: number;
	numbereriorPrice?: number;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;

	Construction?: string;
	RiskOccupation?: number;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	/* INSURED - END */

	/* PREMIUM - START */

	PremiumTotalAmount?: number;
	Status?: ENUM_SPPA_STATUS;

	/* PREMIUM - END */

	/* DISASTER AND RIOT - START */

	EarthquakesTsunamisVolcanicEruptions?: boolean;
	FloodsHurricanesStormHailLandslides?: boolean;
	Riots?: boolean;
	TerrorismAndSabotage?: boolean;

	/* DISASTER AND RIOT - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelProductAsriGallery?: Array<ProductBaseGalleryModel>;
	modelProductAsriPayment?: ProductBaseGalleryModel;

	/* NOT MAPPED - END */

	/* Attribute - END */

	constructor()
	{
		super();
		this.listModelProductAsriGallery = [];
	}


	//#region VALIDATE

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk ASRI");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductAsriGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductAsriGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductAsriGallery of this.listModelProductAsriGallery ?? [])
			{
				modelResponseProductAsriGallery = modelProductAsriGallery.validateAdd();

				if (modelResponseProductAsriGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductAsriGallery;
				}
				else
				{
					numberSize += modelProductAsriGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductAsriGallery?.filter(modelProductAsriGallery => modelProductAsriGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk ASRI");

		if(this.listModelProductAsriGallery == null || this.listModelProductAsriGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateForCoverage(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		if (this.CoverageCode == null || this.CoverageCode === undefined || this.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong. Silahkan kontak pengembang!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateForBuildingPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Harga Bangunan");

		if (this.BuildingPrice == null || this.BuildingPrice === undefined || this.BuildingPrice === 0)
		{
			modelResponse.MessageContent = "Harga bangunan tidak boleh kosong.";
		}
		else if(this.BuildingPrice < RuleConstant.NUMBER_BUILDINGPRICE_VALUEMINIMUM)
		{
			modelResponse.MessageContent = "Harga bangunan minimal 1 Juta Rupiah!";
		}
		else if (this.BuildingPrice > RuleConstant.NUMBER_BUILDINGPRICE_VALUEMAXIMUM)
		{
			modelResponse.MessageContent = "Harga bangunan maksimal atau sama dengan 3 Miliar Rupiah";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateForInteriorPricePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Harga Interior");

		if (this.InteriorPrice === 0)
		{
			modelResponse.MessageContent = "Jika diisi, harga interior tidak boleh 0.";
		}
		else if ((this.InteriorPrice != null || this.InteriorPrice !== undefined) && this.InteriorPrice < RuleConstant.NUMBER_INTERIORPRICE_VALUEMINIMUM )
		{
			modelResponse.MessageContent = "Harga minimal interior bangunan 100 Ribu Rupiah";
		}
		else if ((this.InteriorPrice !== undefined && this.BuildingPrice !== undefined) && (this.InteriorPrice > (this.BuildingPrice * 0.1 )))
		{
			modelResponse.MessageContent = "Harga perlengkapan tidak dapat lebih besar 10% dari harga bangunan.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateCheckForCalculatePremiumAsri(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateForCoverage();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForBuildingPrice();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForInteriorPricePrice();
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckForObjectInformationAsri(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHHYPEN);

		modelResponse.setForValidation("Informasi Objek");

		if (this.BuildingAddress == null || this.BuildingAddress === undefined || this.BuildingAddress === "")
		{
			modelResponse.MessageContent = "Alamat bangunan yang diasuransikan tidak boleh kosong!";
		}
		else if (this.BuildingAddress.length < RuleConstant.NUMBER_BUILDINGADDRESS_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Alamat bangunan yang diasuransikan minimal terdiri dari 10 karakter!";
		}
		else if (this.BuildingPostalCode == null || this.BuildingPostalCode === undefined || this.BuildingPostalCode === "")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong!";
		}
		else if (!regularExpression.test(this.BuildingPostalCode))
		{
			modelResponse.MessageContent = "Kode pos hanya boleh berisi angka, huruf, atau strip!";
		}
		else if (this.BuildingNearMarket === true)
		{
			modelResponse.MessageContent = "Bangunan yang diasuransikan berada di dekat pasar, tidak dapat melanjutkan proses.";
		}
		else if (this.BuildingWorkPlace === true)
		{
			modelResponse.MessageContent = "Bangunan yang diasuransikan digunakan sebagai kantor, tidak dapat melanjutkan proses.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductAsriGallery !== null && this.listModelProductAsriGallery !== undefined)
		{
			if (this.listModelProductAsriGallery.length > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{

			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setBuildingLocationFromPostal(modelLocation: LocationModel): void
	{
		this.BuildingCityName = modelLocation.CityName;
		this.BuildingCountryName = modelLocation.CountryName;
		this.BuildingPostalID = modelLocation.PostalID;
		this.BuildingPostalName = modelLocation.PostalName;
		this.BuildingProvinceName = modelLocation.ProvinceName;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.BuildingFront,
				Name : "Tampak Depan*"
			},
			{
				Type: enumPhotoType.BuildingLeft,
				Name : "Tampak Kiri*"
			},
			{
				Type: enumPhotoType.BuildingRight,
				Name : "Tampak Kanan*"
			},
			{
				Type: enumPhotoType.BuildingBack,
				Name : "Tampak Belakang*"
			},
		];

		return arrayModelGalleryModel;
	}

	//#endregion


	//#region GENERATE

	setInterfaceDocument(): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		arrayModelGalleryModel =
		[
			enumPhotoType.BuildingFront,
			enumPhotoType.BuildingLeft,
			enumPhotoType.BuildingRight,
			enumPhotoType.BuildingBack,
		];

		return arrayModelGalleryModel;
	}

	//#endregion


	//#region CLEAN

	cleanForProductASRI()
	{
		this.BuildingWorkPlace = false;
		this.BuildingNearMarket = false;
		this.EarthquakesTsunamisVolcanicEruptions = false;
		this.FloodsHurricanesStormHailLandslides = false;
		this.Riots = false;
		this.TerrorismAndSabotage = false;
	}

	//#endregion

}

//#endregion