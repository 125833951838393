//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { LicensePlatePrefixModel } from "./licenseplateprefix.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";
import { VehicleBrandModel } from "./vehiclebrand.model";
import { VehicleColorModel } from "./vehiclecolor.model";
import { VehicleFunctionModel } from "./vehiclefunction.model";
import { VehicleLocationModel } from "./vehiclelocation.model";
import { VehicleManufactureYearModel } from "./vehiclemanufactureyear.model";
import { VehicleModelModel } from "./vehiclemodel.model";
import { VehicleSubModelModel } from "./vehiclesubmodel.model";
import { VehicleTypeModel } from "./vehicletype.model";

//#endregion


//#region CLASS

export class ProductOtoModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product oto model, used for SPPA and policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Tuesday, 24 May 2022.
		Created by : Andri Septiawan.						Updated by : Eka Saputra.
		Version : 1.0:3.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;

	Disclaimer?: boolean;
	SurveyOtoID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;
	PolicyholderEmail?: string;

	/* POLICYHOLDER - END */

	/* INSURED - START */

	VehiclePrice?: number;
	EquipmentNonStandardPrice?: number;
	EquipmentNonStandardNote?: string;
	VehicleRegionCode?: string;
	VehicleRegionName?: string;
	LicensePlatePrefixID?: number;
	LicensePlatePrefixCode?: string;
	LicensePlatePrefixName?: string;
	LicensePlateCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;

	VehicleManufactureYearID?: number;
	VehicleManufactureYearCode?: string;
	VehicleManufactureYearName?: string;
	VehicleBrandID?: number;
	VehicleBrandCode?: string;
	VehicleBrandName?: string;
	VehicleModelID?: number;
	VehicleModelCode?: string;
	VehicleModelName?: string;
	VehicleSubModelID?: number;
	VehicleSubModelCode?: string;
	VehicleSubModelName?: string;
	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: number;
	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;
	VehicleFunctionID?: number;
	VehicleFunctionCode?: number;
	VehicleLocationID?: number;
	VehicleLocationCode?: string;

	VehicleSerie?: string;
	VehicleChasisNumber?: string;
	VehicleEngineNumber?: string;
	VehicleSeatCapacity?: string;
	AuthorizedWorkshop?: boolean;

	/* INSURED - END */


	/* CUSTOM - START */

	Comprehensive?: boolean;
	TotalLossOnly?: boolean;
	ThirdPartyLegal?: boolean;
	ThirdPartyLegalValue?: number;
	FloodTyphoonHurricaneHailLandslide?: boolean;
	Riots?: boolean;
	TerrorismAndSabotage?: boolean;
	EarthquakesTsunamisVolcanicEruptions?: boolean;
	DriverPersonalAccident?: boolean;
	PassengerPersonalAccident?: boolean;

	/* CUSTOM - END */


	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	modelVehicleBrand?: VehicleBrandModel;
	modelVehicleModel?: VehicleModelModel;
	modelVehicleSubModel?: VehicleSubModelModel;
	modelVehicleType?: VehicleTypeModel;
	modelVehicleColor?: VehicleColorModel;
	modelVehicleManufactureYear?: VehicleManufactureYearModel;
	modelVehicleFunction?: VehicleFunctionModel;
	modelVehicleLocation?: VehicleLocationModel;
	modelLicensePlatePrefix?: LicensePlatePrefixModel;


	listModelProductOtoGallery?: Array<ProductBaseGalleryModel>;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelProductOtoGallery = [];
	}

	//#endregion


	//#region VALIDATION

	validateforVehiclePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Harga kendaraan tidak boleh kosong!";
			return modelResponse;
		}
		else if (this.VehiclePrice < 1000000)
		{
			modelResponse.MessageContent = "Minimum harga kendaraan adalah 1 Juta!";
			return modelResponse;
		}
		else if (this.VehiclePrice > 2000000000)
		{
			modelResponse.MessageContent = "Maksimal harga kendaraan adalah 2 Milyar!";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleManufactureYearCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined || this.VehicleManufactureYearCode === "")
		{
			modelResponse.MessageContent = "Tahun kendaraan tidak boleh kosong!";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforCoverageCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.CoverageCode == null || this.CoverageCode === undefined || this.CoverageCode === "")
		{
			modelResponse.MessageContent = "Cakupan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleBrandCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleBrandCode == null || this.VehicleBrandCode === undefined || this.VehicleBrandCode === "")
		{
			modelResponse.MessageContent = "Merek kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleModelCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleModelCode == null || this.VehicleModelCode === undefined || this.VehicleModelCode === "")
		{
			modelResponse.MessageContent = "Model kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleLocationCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleLocationCode == null || this.VehicleLocationCode === undefined || this.VehicleLocationCode === "")
		{
			modelResponse.MessageContent = "Wilayah penggunaan kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforEquipmentNonStandard(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.EquipmentNonStandardNote == null || this.EquipmentNonStandardNote === undefined || this.EquipmentNonStandardNote === "")
		{
			modelResponse.MessageContent = "Perlengkapan non standar tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforEquipmentNonStandardPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if((this.EquipmentNonStandardPrice !== undefined && this.VehiclePrice !== undefined) && (this.EquipmentNonStandardPrice > (this.VehiclePrice * 0.1)))
		{
			modelResponse.MessageContent = "Jumlah harga perlengkapan non standar tidak dapat melebihi 10% dari harga kendaraan!";
		}
		else if(this.EquipmentNonStandardPrice !== undefined && this.EquipmentNonStandardPrice <= 99999)
		{
			modelResponse.MessageContent = "Minimal jumlah harga perlengkapan non standar adalah 100 Ribu!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateCheckForCalculatePremiumOto(): ResponseModel
	{
		// let modelResponse: ResponseModel = this.validateforCoverageCode();
		let modelResponse: ResponseModel = this.validateforVehicleBrandCode();

		// if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		// {
		// 	modelResponse = this.validateforVehicleBrandCode();
		// }
		// else
		// {

		// }

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleModelCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleLocationCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleManufactureYearCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehiclePrice();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforEquipmentNonStandardPrice();
		}
		else
		{

		}

		modelResponse.MessageTitle = "Produk OTO";
		return modelResponse;
	}

	validateforVehiclePriceOtoDaily(numberPrice: number): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Harga kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else if (this.VehiclePrice > numberPrice)
		{
			modelResponse.MessageContent = "Maksimal harga kendaraan adalah "+ numberPrice;
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductOtoGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	cleanForProductOTOYearly(): void
	{

		this.Comprehensive = false;
		this.DriverPersonalAccident = false;
		this.EarthquakesTsunamisVolcanicEruptions = false;
		this.FloodTyphoonHurricaneHailLandslide = false;
		this.PassengerPersonalAccident = false;
		this.Riots = false;
		this.TerrorismAndSabotage = false;
		this.ThirdPartyLegal = false;
		this.TotalLossOnly = false;
		this.ThirdPartyLegalValue = 0.0;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Oto");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Oto");

		if(this.listModelProductOtoGallery == null || this.listModelProductOtoGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductOtoGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductOtoGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductOtoGallery of this.listModelProductOtoGallery ?? [])
			{
				modelResponseProductOtoGallery = modelProductOtoGallery.validateAdd();

				if (modelResponseProductOtoGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductOtoGallery;
				}
				else
				{
					numberSize += modelProductOtoGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductOtoGallery?.filter(modelProductOtoGallery => modelProductOtoGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	validateCheckForUpdateInsured(booleanLicesePlate: boolean): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateforVehicleColor();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if(booleanLicesePlate === true)
			{
				modelResponse = this.validateforLicensePlate();
			}
			else
			{
				modelResponse = this.validateforVehicleRegion();
			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleChasisNumber();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleEngineNumber();
		}
		else
		{

		}

		modelResponse.MessageTitle = "Produk OTO";
		return modelResponse;
	}

	validateforLicensePlate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "")
		{
			modelResponse.MessageContent = "Plat nomor depan tidak boleh kosong.";
			return modelResponse;
		}
		else if(this.LicensePlateInfix == null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "")
		{
			modelResponse.MessageContent = "Plat nomor tidak boleh kosong.";
			return modelResponse;

		}
		else if(this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "")
		{
			modelResponse.MessageContent = "Plat nomor belakang tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleRegion(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleRegionCode == null || this.VehicleRegionCode === undefined || this.VehicleRegionCode === "")
		{
			modelResponse.MessageContent = "Kode wilayah penggunaan tidak boleh kosong.";
			return modelResponse;
		}
		else if(this.VehicleRegionName == null || this.VehicleRegionName === undefined || this.VehicleRegionName === "")
		{
			modelResponse.MessageContent = "Wilayah penggunaan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleColor(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleColorCode == null || this.VehicleColorCode === undefined || this.VehicleColorCode === "")
		{
			modelResponse.MessageContent = "Warna kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleChasisNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleChasisNumber == null || this.VehicleChasisNumber === undefined || this.VehicleChasisNumber === "")
		{
			modelResponse.MessageContent = "Nomor rangka kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleEngineNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleEngineNumber == null || this.VehicleEngineNumber === undefined || this.VehicleEngineNumber === "")
		{
			modelResponse.MessageContent = "Nomor mesin kendaraan tidak boleh kosong.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateLicensePlate(numberLicensePlate: number): void
	{
		if(this.LicensePlateInfix !== undefined && Number(this.LicensePlateInfix) > 9999)
		{
			this.LicensePlateInfix = "9999";
		}
		else
		{
			this.LicensePlateInfix = numberLicensePlate.toString();
		}
	}

	//#endregion


	//#region GENERATE

	generateManufactureYear(numberManufactureYearCoverage: number): Array<number>
	{
		// deepcode ignore ArrayConstructor: <please specify a reason of ignoring this>
		const arrayNumberManufactureYear: Array<number> = new Array<number>();
		const numberYear: number = new Date().getFullYear();
		const numberMinimunYear: number = numberYear - numberManufactureYearCoverage;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberManufactureYear.push(numberYearCalc);
		}

		return arrayNumberManufactureYear;
	}

	//#endregion


	//#region SET

	setInterfaceDocument(): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		arrayModelGalleryModel =
		[
			enumPhotoType.KTP,
			enumPhotoType.DrivingLicense,
			enumPhotoType.STNK,
			enumPhotoType.KirReport,
			enumPhotoType.VehicleFront,
			enumPhotoType.VehicleLeft,
			enumPhotoType.VehicleRight,
			enumPhotoType.VehicleBack
		];

		return arrayModelGalleryModel;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.VehicleFront,
				Name : "Tampak Depan*"
			},
			{
				Type: enumPhotoType.VehicleLeft,
				Name : "Tampak Kiri*"
			},
			{
				Type: enumPhotoType.VehicleRight,
				Name : "Tampak Kanan*"
			},
			{
				Type: enumPhotoType.VehicleBack,
				Name : "Tampak Belakang*"
			},
			{
				Type: enumPhotoType.VehicleInterior,
				Name : "Interior*"
			},
			{
				Type: enumPhotoType.KTP,
				Name : "KTP*"
			},
			{
				Type: enumPhotoType.STNK,
				Name : "STNK*"
			},
		];

		return arrayModelGalleryModel;
	}

	//#endregion


	//#region SETTER

	setCoverageCodeForOtoDaily(): void
	{
		this.CoverageCode = "PAYD-F";
	}

	setAttributtesForOtoMonthly(): void
	{
		this.Comprehensive = true;
		this.DriverPersonalAccident = false;
		this.EarthquakesTsunamisVolcanicEruptions = false;
		this.FloodTyphoonHurricaneHailLandslide = false;
		this.PassengerPersonalAccident = false;
		this.Riots = false;
		this.TerrorismAndSabotage = false;
		this.ThirdPartyLegal = false;
		this.TotalLossOnly = false;
		this.ThirdPartyLegalValue = 0.0;
	}

	setCoverageCodeOtoMonthly(): void
	{
		if(this.Comprehensive === false && this.TotalLossOnly === true)
		{
			this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_OTOCUSTOM2;
		}
		else if(this.Comprehensive === true && this.TotalLossOnly === false)
		{
			this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_OTOCUSTOM1;
		}
		else
		{
			this.CoverageCode = StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY;
		}
	}

	//#endregion

}

//#endregion