//#region IMPORT

import { ENUM_HTTP_STATUS, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { ResponseCodeConstant } from "../constants/responsecode.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { TicketModel } from "./ticket.model";

//endregion


//region CLASS

export class ResponseModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for response model.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 Decemeber 2020.			    Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						    Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	HTTPResponseCode?: ENUM_HTTP_STATUS;
	ServiceResponseCode?: string;
	MessageTitle?: string;
	MessageContent?: string;
	Data?: string;
	State?: ENUM_RESPONSE_STATE;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.State = ENUM_RESPONSE_STATE.Nothing;
	}

	//#endregion


	//#region SETTER

	setServiceTooLarge(): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = ResponseCodeConstant.STRING_RESPONSECODE_CASE_GENERAL_SERVICETOOLARGE;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setSessionExpired(): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = ResponseCodeConstant.STRING_RESPONSECODE_CASE_GENERAL_SESSIONEXPIRED;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setWebsiteServiceException(stringMessageError: string): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = stringMessageError;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForValidation(stringMessageTitle: string): void
	{
		this.MessageTitle = stringMessageTitle;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForANONotFound(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_ANO_NOTFOUND;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForInsertDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_INSERTDATA_SUCCESS;
	}

	setForVerifyDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_VERIFYDATA_SUCCESS;
	}

	setForSelectHistoryPolicy(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_SELECT_HISTORYPOLICY;
	}

	setReturnContentWithTicketNumber(): void
	{
		if (this.Data != null || this.Data !== undefined)
		{
			const modeTicket: TicketModel = new TicketModel();

			modeTicket.setModelFromString(this.Data);
			this.MessageTitle = StringConstant.STRING_MODULE_TICKETNUMBER;
			this.MessageContent = this.MessageContent+" "+modeTicket.TicketNumber;
		}
	}

	setReturnContentWithTicketModel(): TicketModel
	{
		if (this.Data != null || this.Data !== undefined)
		{
			const modelTicket: TicketModel = new TicketModel();

			modelTicket.setModelFromString(this.Data);
			return modelTicket;
		}
		else
		{
			return new TicketModel();
		}
	}

	//#endregion
}

//#endregion